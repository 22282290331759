<template>
    <div class="house-info">
        <div v-if="type=='column'" class="box f-x-b-c">
            <div v-if="showSalesRoomNo" class="item f-y-s-s">
                <span class="item-label" :style="{color:textColor}">门店</span>
                <span class="item-val" :style="{color:textColor}">{{ formartRoom(data.salesRoomNo) || '总旗舰店' }}</span>
            </div>
            <div v-if="showDesignStyle" class="item f-y-s-s">
                <span class="item-label" :style="{color:textColor}">风格</span>
                <span class="item-val" :style="{color:textColor}">{{
                    formartDesStyle(data.designStyle)
                }}</span>
            </div>
            <div v-if="showHouseArea" class="item f-y-s-s">
                <span class="item-label" :style="{color:textColor}">面积</span>
                <span class="item-val" :style="{color:textColor}">{{ formartHouseArea(data.houseArea) }}</span>
            </div>
            <div v-if="showHouseType" class="item f-y-s-s">
                <span class="item-label" :style="{color:textColor}">户型</span>
                <span class="item-val" :style="{color:textColor}">{{
                    formartHouseStyle(data.houseType)
                }}</span>
            </div>
            
        </div>
        <div v-if="type=='row'" class="box1 f-x-s-c">
            <div v-if="showSalesRoomNo" class="item" :class="'f'+textSize" :style="{color:textColor}" >
                {{ formartDesStyle(data.designStyle) }}
            </div>
            <div v-if="showHouseType" class="cus-line"></div>
            <div v-if="showHouseType" class="item" :class="'f'+textSize" :style="{color:textColor}">
                {{ formartHouseStyle(data.houseType) }}
            </div>
            <div v-if="showHouseArea" class="cus-line"></div>
            <div v-if="showHouseArea" class="item" :class="'f'+textSize" :style="{color:textColor}">
                {{ formartHouseArea(data.houseArea) }}
            </div>
        </div>
    </div>
	
</template>
<script>
import {useDesStyleDict,useHousetypeDict,useHouseAreasDict,useSalesRoomDict} from '@/utils/frequent.js'
export default {
	props: {
		data: {
			type: Object,
			default: () => {},
		},
        showSalesRoomNo:{
            type:Boolean,
            default:true
        },
        showDesignStyle:{
            type:Boolean,
            default:true
        },
        showHouseArea:{
            type:Boolean,
            default:true
        },
        showHouseType:{
            type:Boolean,
            default:true
        },
        type:{
            type:String,
            default:"column"
        },
        textColor:{
            type:String,
            default:"#fff"
        },
        textSize:{
            type:Number,
            default:32
        }
	},
	data() {
		return {
			salesRoomList: [],
			desStyleList: [],
			housetypeList: [],
			houseAreasList: [],
		}
	},
	async mounted() {
        this.salesRoomList = await useSalesRoomDict()
        this.desStyleList = await useDesStyleDict()
        this.housetypeList = await useHousetypeDict()
        this.houseAreasList = await useHouseAreasDict()
	},
	methods: {
		//格式化门店
		formartRoom(value) {
			var re = ''
			this.salesRoomList.forEach((obj) => {
				if (obj.showRoomNo === value) {
					re = obj.showRoomName
				}
			})
			return re
		},
		//格式化设计风格
		formartDesStyle(value) {
			var re = ''
			this.desStyleList.forEach((obj) => {
				if (obj.value === value) {
					re = obj.label
				}
			})
			return re
		},

		//格式化户型类型
		formartHouseStyle(value) {
			var re = ''
			this.housetypeList.forEach((obj) => {
				if (obj.value === value) {
					re = obj.label
				}
			})
			return re
		},

		//格式化面积类型
		formartHouseArea(value) {
			var re = ''
			this.houseAreasList.forEach((obj) => {
				if (obj.value == value) {
					re = obj.label
				}
			})
			return re
		},
	},
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
	.box {
		width: 100%;
		.item {
			min-width: 50px;
            min-height: 45px;
			.item-label {
				
				font-weight: 400;
				font-size: 13px;
			}
			.item-val {
				margin-top: 5px;
				
				font-weight: 500;
				font-size: 15px;
                white-space: nowrap;
			}
		}
	}
    .box1 {
		width: 100%;
        height: 30px;
		.item {
            
            font-weight: 400;
            // font-size: 32px;
		}
        .cus-line{
            width: 1px;
            height: 10px;
            background: #E5E5E5;
            margin: 0 10px;
        }
	}
}
@media screen and (min-width: 900px) {
	.box {
		width: 100%;
		.item {
			min-width: 100px;
            margin-right: 50px;
			.item-label {
				
				font-weight: 300;
				font-size: 24px;
			}
			.item-val {
				margin-top: 10px;
				
				font-weight: 300;
				font-size: 30px;
                white-space: nowrap;
			}
		}
	}
    .box1 {
		width: 100%;
        height: 50px;
		.item {
            
            font-weight: 400;
            // font-size: 32px;
		}
        .cus-line{
            width: 1px;
            height: 20px;
            background: #E5E5E5;
            margin: 0 20px;
        }
	}
}
</style>
