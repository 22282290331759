<template>
	<div class="visitor-form f-y-c-c">
		<span class="title">{{ title }}</span>
		<span class="desc">{{ desc }}</span>
		<div class="box f-y-s-c">
            <el-form
                :inline="true" 
                ref="ruleFormRef"
                :model="visitorInfo"
                :rules="rules"
                class="cus-form"
            >
                <el-form-item   >
                    <el-cascader
                        class="input city"
                        placeholder="您选择所在地区"
                        size="default"
                        :options="cityStoreList"
                        v-model="city"
                    >
                    </el-cascader>
                </el-form-item>
                <el-form-item  prop="houseArea" >
                    <el-input
                        class="input"
                        placeholder="请输入您的房屋面积"
                        v-model="visitorInfo.houseArea"
                        inputmode="numeric"
                    >
                        <div slot="suffix" class="m2">m²</div>
                    </el-input>
                </el-form-item>
                <el-form-item   prop="mobile">
                    <el-input
                        class="input"
                        placeholder="请输入您的手机号"
                        v-model="visitorInfo.mobile"
                        inputmode="numeric"
                    >
                    </el-input>
                </el-form-item>
            </el-form>

            
            <!-- <el-cascader
                class="input city"
                placeholder="您选择所在地区"
                size="default"
                :options="cityStoreList"
                v-model="city"
            >
            </el-cascader>
			<el-input
				class="input"
				placeholder="请输入您的房屋面积"
				v-model="visitorInfo.houseArea"
                inputmode="numeric"
			>
				<div slot="suffix" class="m2">m²</div>
			</el-input>
			<el-input
				class="input"
				placeholder="请输入您的手机号"
				v-model="visitorInfo.mobile"
                inputmode="numeric"
			>
			</el-input> -->
		</div>
        <div @click="submitForm" class="btn f-x-c-c">提交留言</div>
        <span class="tip">为了你的权益，你的隐私将被严格保密</span>
        <MessageForm ref="refMessage" :list="errList"></MessageForm>
	</div>
</template>
<script>
import { postmobile } from '@/api/views/name.js'
import {
	regionData,
	provinceAndCityData,
	codeToText,
} from 'element-china-area-data'
import {useCityInfo} from '@/utils/frequent.js'
export default {
	props: {
		title: {
			type: String,
			default: '免费装修方案',
		},
		desc: {
			type: String,
			default: '设计+户型+施工+人工+售后',
		},
        adUnitCode: {
			type: String,
            default:"XSdw"
		},
	},
	data() {
        var validatePhone = (rule, value, callback) => {
            if (!value.match(/^[1][3,4,5,6.7,8,9][0-9]{9}$/)) {
                callback('请输入正确的电话号码');
            }else{
                callback();
            }
            
        };
		return {
            city:[],
            provinceAndCityData,
            cityStoreList:[],
			visitorInfo: {
				houseArea: '',
				mobile: '', //电话
				name: '客户',
				bizSource: 'pc',
				interviewId: '', // 访次id
				visitorId: '', //访客id
				mediaName: '', // 媒体名称
				channelCode: 'website', // 渠道代码
				mediaCode: '', //媒体来源代码
				activityCode: '', // 营销活动代码
				projectName: '', // 营销计划名称
				projectCode: '', // 营销计划也就是广告计划的代码
				adUnitCode: '', // 对应当前页面的广告单元代码
				reqUrl: '', // 提交信息的时候对应当前页面的url
			},
            rules:{
                // houseArea: [
                //     { required: true, message: ' ', trigger: 'blur' },
                // ],
                mobile: [
                    { required: true, message: ' ', trigger: 'blur' },
                    { validator: validatePhone, trigger: 'blur' },
                ],
            },
            errList:[],
            timer:"",
		}
	},
	async mounted() {
		this.setAccessInfo()
        this.cityStoreList = await useCityInfo()
        if(this.curCityInfo.value!='00') this.setCity()
	},
	methods: {
		setAccessInfo() {
            this.visitorInfo.adUnitCode = this.adUnitCode
			this.visitorInfo.interviewId = this.$Cookie.get('MJ0INTERVIEWID')
			this.visitorInfo.visitorId = this.$Cookie.get('MJ0VISITORID')
			this.visitorInfo.reqUrl = window.location.href

            this.visitorInfo.activityCode = this.$Cookie.get('activityCode') 
            this.visitorInfo.projectCode =  this.$Cookie.get('projectCode')
            this.visitorInfo.mediaCode =  this.$Cookie.get('mediaCode')
            this.visitorInfo.projectName =  this.$Cookie.get('projectName')
            this.visitorInfo.mediaName = this.$Cookie.get('mediaName'  )
		},
        async submitForm() {
			try {
                this.$refs.ruleFormRef.validate(async(valid,obj)=>{
                    console.log("aa",obj);
                    if(valid){
                        if(this.city.length){
                            this.visitorInfo.cityCode = this.city[0]
                            this.visitorInfo.salesRoomNo = this.city[1]
                        }

                        const res = await postmobile(this.visitorInfo)
                        this.$refs.refMessage.showDialog('sucess')
                        this.city=[]
                        this.visitorInfo.mobile = ''
                        this.visitorInfo.provinceCode = ''
                        this.visitorInfo.cityCode = ''
                        this.visitorInfo.houseArea = ''
                        this.visitorInfo.name = ''
                        clearTimeout(this.timer)
                        this.timer = setTimeout(()=>{
                            this.$refs.refMessage.closeDialog()
                        },8000)
                    }else{
                        this.$refs.refMessage.showDialog('error')
                        const res = []
                        for(const item in obj){
                            if(item=='houseArea') res.push('请填写您家房屋面积')
                            if(item=='mobile'){
                                if(obj[item][0].message!=' ')  res.push(obj[item][0].message)
                                else res.push('请填写您的电话号码')
                            } 
                        }
                        this.errList = res
                        clearTimeout(this.timer)
                        this.timer = setTimeout(()=>{
                            this.$refs.refMessage.closeDialog()
                        },8000)
                        return false;
                    }
                })
			} catch (err) {
				console.log(err)
			}
		},
		// async submit() {
		// 	try {
		// 		if (
		// 			!this.visitorInfo.mobile.match(
		// 				/^[1][3,4,5,6.7,8,9][0-9]{9}$/
		// 			)
		// 		) {
		// 			this.$message.closeAll()
		// 			this.$message({
		// 				message: '请输入正确的手机号',
		// 				type: 'warning',
		// 			})
		// 			// this.visitorInfo.mobile = ''
		// 		} else {
        //             if(this.city.length){
		// 			    this.visitorInfo.cityCode = this.city[0]
        //                 this.visitorInfo.salesRoomNo = this.city[1]
        //             }

		// 			const res = await postmobile(this.visitorInfo)
        //             this.city=[]
		// 			this.visitorInfo.mobile = ''
		// 			this.visitorInfo.provinceCode = ''
		// 			this.visitorInfo.cityCode = ''
		// 			this.visitorInfo.houseArea = ''
        //             this.visitorInfo.name = ''
		// 			this.$message.closeAll()
		// 			this.$message({
		// 				message:
		// 					'提交成功！稍后家装顾问与您联系，请注意021开头电话',
		// 				duration: 2000,
		// 				offset: 120,
		// 			})
		// 		}
		// 	} catch (err) {
		// 		console.log(err)
		// 	}
		// },
	},
    beforeDestroy(){
        clearTimeout(this.timer)
    }
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .visitor-form {
		width: 100%;
		// height: 300px;
        background: #FFFFFF;
        box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.16);
        border-radius: 5px;
		overflow: hidden;
        padding: 30px 15px;
        box-sizing: border-box;
		.title{
            
            font-weight: bold;
            font-size: 22px;
            color: #120523;
            line-height: 22px;
        }
        .desc{
            margin-top: 8px;
            
            font-weight: 400;
            font-size: 15px;
            color: #444444;
            line-height: 15px;
        }
        .box{
            margin-top: 16px;
            width: 100%;
            .cus-form{
                .el-form-item{
                    width: 100%;
                    /deep/.el-form-item__content{
                        width: 100%;
                    }
                    
                }
            }
            .input{
                width: 100%;
                margin-bottom: 10px;
                /deep/ .el-input{
                    width: 100%;
                    height: 40px;
                    
                    .el-input__inner{
                        width: 100%;
                        height: 40px;
                    }
                }
                /deep/ .el-input__inner{
                    width: 100%;
                    height: 40px;
                    font-size: 12px !important;
                }
                
                   
                /deep/.el-input__suffix{
                    right: 10px;
                    .m2{
                        height: 40px;
                        line-height: 40px;
                        font-size: 12px;
                    }
                }
                /deep/.el-icon-arrow-down{
                    font-size: 12px !important;

                }
            }
            .el-cascader{
                /deep/.el-input__suffix{
                    right: 10px !important;
                }
            }
            
        }
        .btn{
            width: 100%;
            height: 40px;
            background: #753CBE;
            border-radius: 2px;
            
            font-weight: bold;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 40px;
            cursor: pointer;
        }
        .tip{
            
            font-weight: 300;
            font-size: 10px;
            color: #140626;
            line-height: 10px;
            margin-top: 10px;
        }
	}
}
@media screen and (min-width: 900px) {
	.visitor-form {
		width: 100%;
		height: 633px;
        background: #FFFFFF;
        box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.16);
        border-radius: 10px;
		overflow: hidden;
        padding: 30px;
        box-sizing: border-box;
		.title{
            
            font-weight: bold;
            font-size: 36px;
            color: #140626;
            line-height: 36px; 
        }
        .desc{
            
            font-weight: 400;
            font-size: 28px;
            color: #140626;
            margin-top: 18px;
        }
        .box{
            margin-top: 32px;
            width: 100%;
            .cus-form{
                .el-form-item{
                    width: 100%;
                    /deep/.el-form-item__content{
                        width: 100%;
                    }
                    
                }
            }
            .input{
                width: 100%;
                margin-bottom: 30px;
                /deep/ .el-input{
                    width: 100%;
                    height: 80px;
                    
                    .el-input__inner{
                        width: 100%;
                        height: 80px;
                    }
                }
                /deep/ .el-input__inner{
                    width: 100%;
                    height: 80px;
                    font-size: 24px !important;
                }
                
                   
                /deep/.el-input__suffix{
                    right: 20px;
                    .m2{
                        height: 80px;
                        line-height: 80px;
                        font-size: 24px;
                    }
                }
                /deep/.el-icon-arrow-down{
                    font-size: 24px !important;

                }
            }
            .el-cascader{
                /deep/.el-input__suffix{
                    right: 27px !important;
                }
            }
            
        }
        .btn{
            width: 410px;
            height: 80px;
            background: #753CBE;
            border-radius: 4px;
            
            font-weight: bold;
            font-size: 30px;
            color: #FFFFFF;
            line-height: 30px;
            cursor: pointer;
        }
        .tip{
            
            font-weight: 300;
            font-size: 18px;
            color: #140626;
            line-height: 18px;
            margin-top: 15px;
        }
	}
}
</style>
