<template>
	<div class="activity-box">
		<img
            v-if="pcAdList.length"
			@click="navTo(pcAdList[0])"
			class="img"
			:src="pcAdList[0].imgPathPc"
			alt=""
		/>
        <img
            v-if="mobAdList.length"
			@click="navTo(mobAdList[0])"
			class="img-mob"
			:src="mobAdList[0].imgPathPc"
			alt=""
		/>
		<div class="bottom f-x-b-c">
			<span class="text">参与活动特惠</span>
			<div class="box">
                <el-form
                    :inline="true" 
                    ref="ruleFormRef"
                    :model="visitorInfo"
                    :rules="rules"
                    class="cus-form"
                >
                    <el-form-item   prop="mobile">
                        <el-input v-model="visitorInfo.mobile" inputmode="numeric" placeholder="请输入您的手机号">
                            <template #prefix>
                                <el-icon class="el-input__icon"><search /></el-icon>
                            </template>
                            <template #suffix>
                                <div @click="submitForm" class="search-btn f-x-c-c">
                                    立即报名
                                </div>
                            </template>
                        </el-input>
                    </el-form-item>
                    

                </el-form>
                <!-- <el-input v-model="visitorInfo.mobile" inputmode="numeric" placeholder="请输入您的手机号">
                    <template #prefix>
                        <el-icon class="el-input__icon"><search /></el-icon>
                    </template>
                    <template #suffix>
                        <div @click="submit" class="search-btn f-x-c-c">
                            立即报名
                        </div>
                    </template>
                </el-input> -->
			</div>
		</div>
        <MessageForm ref="refMessage" :list="errList"></MessageForm>
	</div>
</template>
<script>
import { postmobile } from '@/api/views/name.js'
import { getadData } from '@/api/advertising/advertising.js'
export default {
	data() {
        var validatePhone = (rule, value, callback) => {
            if (!value.match(/^[1][3,4,5,6.7,8,9][0-9]{9}$/)) {
                callback('请输入正确的电话号码');
            }else{
                callback();
            }
            
        };
		return {
			phone: '',
			adList: [],
            mobAdList:[],
            pcAdList:[],
			visitorInfo: {
				houseArea: '',
				mobile: '', //电话
				name: '客户',
				bizSource: 'pc',
				interviewId: '', // 访次id
				visitorId: '', //访客id
				mediaName: '', // 媒体名称
				channelCode: 'website', // 渠道代码
				mediaCode: '', //媒体来源代码
				activityCode: '', // 营销活动代码
				projectName: '', // 营销计划名称
				projectCode: '', // 营销计划也就是广告计划的代码
				adUnitCode: 'mjkl', // 对应当前页面的广告单元代码
				reqUrl: '', // 提交信息的时候对应当前页面的url
			},
            rules:{
                // houseArea: [
                //     { required: true, message: ' ', trigger: 'blur' },
                // ],
                mobile: [
                    { required: true, message: ' ', trigger: 'blur' },
                    { validator: validatePhone, trigger: 'blur' },
                ],
            },
            errList:[],
            timer:null
		}
	},
	async mounted() {
        this.getMobAdList()
        this.getPcAdList()
        this.setAccessInfo()
	},
	methods: {
		navTo(item) {
            window.open(item.url,item.target)
		},
        getMobAdList(){
            const params = {
                current:1,
                size:1,
                advertisementSpace:'popularActivities',
                isMobile:1
            }
            getadData(params).then((res) => {
                if (res.code == 0) {
                    this.mobAdList  = res.data.records
                } 
            })
        },
        getPcAdList(){
            const params = {
                current:1,
                size:1,
                advertisementSpace:'popularActivities',
                isMobile:0
            }
            getadData(params).then((res) => {
                if (res.code == 0) {
                    this.pcAdList  = res.data.records
                } 
            })
        },
        setAccessInfo(){
            this.visitorInfo.interviewId = this.$Cookie.get('MJ0INTERVIEWID')
            this.visitorInfo.visitorId = this.$Cookie.get('MJ0VISITORID')
            this.visitorInfo.reqUrl = window.location.href

            this.visitorInfo.activityCode = this.$Cookie.get('activityCode') 
            this.visitorInfo.projectCode =  this.$Cookie.get('projectCode')
            this.visitorInfo.mediaCode =  this.$Cookie.get('mediaCode')
            this.visitorInfo.projectName =  this.$Cookie.get('projectName')
            this.visitorInfo.mediaName = this.$Cookie.get('mediaName'  )
        },
        async submitForm() {
			try {
                this.$refs.ruleFormRef.validate(async(valid,obj)=>{
                    if(valid){
                        const res = await postmobile(this.visitorInfo)
                        this.$refs.refMessage.showDialog('sucess')
                        this.visitorInfo.mobile = ''
                        this.visitorInfo.provinceCode = ''
                        this.visitorInfo.cityCode = ''
                        this.visitorInfo.houseArea = ''
                        this.visitorInfo.name = ''
                        clearTimeout(this.timer)
                        this.timer = setTimeout(()=>{
                            this.$refs.refMessage.closeDialog()
                        },8000)
                    }else{
                        this.$refs.refMessage.showDialog('error')
                        const res = []
                        for(const item in obj){
                            if(item=='houseArea') res.push('请填写您家房屋面积')
                            if(item=='mobile'){
                                if(obj[item][0].message!=' ')  res.push(obj[item][0].message)
                                else res.push('请填写您的电话号码')
                            } 
                        }
                        this.errList = res
                        clearTimeout(this.timer)
                        this.timer = setTimeout(()=>{
                            this.$refs.refMessage.closeDialog()
                        },8000)
                        return false;
                    }
                })
			} catch (err) {
				console.log(err)
			}
		},
        // async submit(){
        //     try {
        //         if (!this.visitorInfo.mobile.match(/^[1][3,4,5,6.7,8,9][0-9]{9}$/)) {
        //             this.$message.closeAll()
        //             this.$message({
        //                 message: '请输入正确的手机号',
        //                 type: 'warning',
        //             })
        //             this.visitorInfo.mobile = ''
        //         } else {
        //             const res = await postmobile(this.visitorInfo)
        //             this.visitorInfo.mobile = ''
        //             this.$message.closeAll()
        //             this.$message({
        //                 message:'提交成功！稍后家装顾问与您联系，请注意021开头电话',
        //                 duration: 2000,
        //                 offset:120
        //             })
        //         }
        //     }catch(err){
        //         console.log(err);
        //     }
        // }
	},
    beforeDestroy(){
        clearTimeout(this.timer)
    }
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .activity-box {
		width: 100%;
		height: 250px;
		background: #ffffff;
		box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
		border-radius: 5px;
		overflow: hidden;
        display: flex;
        flex-flow: column;
        justify-content: center;
        .img{
            display: none;
        }
		.img-mob {
			width: 100%;
			height: 210px;
            object-fit: cover;
		}
		.bottom {
			padding: 5px 10px;
			width: 100%;
			box-sizing: border-box;
            height: 40px;
            display: flex;
            align-items: center;
			.text {
				
				font-weight: 400;
				font-size: 13px;
				color: #140626;
                width: 85px;
                margin-right: 10px;
                height: 30px;
                line-height: 30px;
			}
			.box {
                height: 30px;
                .cus-form{
                    height: 30px;
                    .el-form-item{
                        height: 30px;
                        /deep/.el-form-item__content{
                            height: 30px;
                        }
                    }
                }
				/deep/.el-input {
					border-radius: 25px;
					overflow: hidden;
					width: 240px;
					height: 30px;
                    display: flex;
				}
				/deep/.el-input__inner {
					width: 100% !important;
					height: 100% !important;
					border-radius: 25px;
					padding-right: 0px !important;
					padding-left: 15px !important;
					overflow: hidden;
					font-size: 13px !important;
				}
				/deep/ .el-input__suffix {
					right: 0px !important;
				}
				.search-btn {
					cursor: pointer;
					width: 70px;
					height: 30px;
					background: #6c1ec8;
					border-radius: 25px 0px 0px 25px;
					
					font-weight: bold;
					font-size: 13px;
					color: #ffffff;
					line-height: 30px;
				}
			}
		}
	}

}
@media screen and (min-width: 900px) {
	.activity-box {
		width: 100%;
		height: 686px;
		background: #ffffff;
		box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
		border-radius: 10px;
		overflow: hidden;
        .img-mob{
            display: none;
        }
		.img {
			width: 100%;
			height: 550px;
            cursor: pointer;
            object-fit: cover;
		}
		.bottom {
			padding: 23px 30px;
			width: 100%;
			box-sizing: border-box;
			.text {
				
				font-weight: 400;
				font-size: 40px;
				color: #140626;
                width: 240px;
                margin-right: 27px;
			}
			.box {
                .cus-form{
                    height: 90px;
                    .el-form-item{
                        height: 90px;
                        /deep/.el-form-item__content{
                            height: 90px;
                        }
                    }
                }
				/deep/.el-input {
					border-radius: 50px;
					overflow: hidden;
					width: 692px;
					height: 90px;
				}
				/deep/.el-input__inner {
					width: 100% !important;
					height: 100% !important;
					border-radius: 50px;
					padding-right: 0px !important;
					overflow: hidden;
					font-size: 40px !important;
				}
				/deep/ .el-input__suffix {
					right: 0px !important;
				}
				.search-btn {
					cursor: pointer;
					width: 250px;
					height: 90px;
					background: #6c1ec8;
					border-radius: 50px 0px 0px 50px;
					
					font-weight: bold;
					font-size: 40px;
					color: #ffffff;
					line-height: 40px;
				}
			}
		}
	}
}
</style>
